import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private pathArray: string[] = [];
  private delay: number = 1000; // Delay set to 1000 milliseconds
  
  // constructor(private http: HttpClient) {
  //   window.addEventListener('click', () => {
  //     this.pathArray.length = 0;
  //   });
  // }

  constructor(private http: HttpClient) {
    // Add keydown event listener to listen for spacebar events
    window.addEventListener('keydown', this.handleSpacebar.bind(this));
  }

  private handleSpacebar(event: KeyboardEvent): void {
    // Check if the pressed key is 'Space'
    if (event.code === 'Space' || event.keyCode === 32) {
      this.pathArray.length = 0;
    }
  }


  private printPathAndMethod(method: string, path: string): void {
    const print = `${method} : ${path}`;
    this.pathArray.push(print);
    // console.log(this.pathArray);
  }

  private pushPathWithDelay(method: string, path: string): void {
    setTimeout(() => {
      
      this.printPathAndMethod(method, path);
    }, this.delay);
  }

  get<T>(path: string, queryParams: Params = {}, headers = {}): Observable<T> {
    this.pushPathWithDelay('GET', path);
    let params: Params = {};
    if (queryParams) {
      params = this.setParameter(queryParams);
    }
    headers = this.customHeaders(headers);
    return this.http.get<T>(this.path(path), { headers: headers, params });
  }

  put<T>(path: string, payload = {}, headers = {}): Observable<T> {
    this.pushPathWithDelay('PUT', path);
    headers = this.customHeaders(headers);
    return this.http.put<T>(this.path(path), payload, { headers: headers });
  }

  post<T>(path: string, payload = {}, headers = {}, queryParams: Params = {}): Observable<T> {
    this.pushPathWithDelay('POST', path);
    let params: Params = {};
    if (queryParams) {
      params = this.setParameter(queryParams);
    }
    headers = this.customHeaders(headers);
    return this.http.post<T>(this.path(path), payload, { headers: headers, params });
  }

  patch<T>(path: string, payload = {}, headers = {}, queryParams: Params = {}): Observable<T> {
    this.pushPathWithDelay('PATCH', path);
    let params: Params = {};
    if (queryParams) {
      params = this.setParameter(queryParams);
    }
    headers = this.customHeaders(headers);
    return this.http.patch<T>(this.path(path), payload, { headers: headers, params });
  }

  delete<T>(path: string, headers = {}): Observable<T> {
    this.pushPathWithDelay('DELETE', path);
    headers = this.customHeaders(headers);
    return this.http.delete<T>(this.path(path), { headers: headers });
  }

  private customHeaders(customHeaders: any = {}): HttpHeaders {
    let headers = new HttpHeaders();
    for (const key in customHeaders) {
      if (key && customHeaders[key] !== undefined && customHeaders[key] !== null) {
        headers = headers.append(key, customHeaders[key]);
      }
    }
    return headers;
  }

  private setParameter(routerParams: Params): HttpParams {
    let queryParams = new HttpParams();
    for (const key in routerParams) {
      if (key && routerParams[key] !== undefined && routerParams[key] !== null) {
        queryParams = queryParams.set(key, routerParams[key]);
      }
    }
    return queryParams;
  }

  private path(path: string): string {
    // The actual implementation should use an environment configuration
    // to construct the full URL to the backend service
    return `${path}`;
  }

  getPathArray(): string[] {
    return this.pathArray;
  }

}