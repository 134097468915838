
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectIamRoleService {
  constructor(private apiService: ApiService) {
  }

  list(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/roles/list`, data) as Observable<any>;
  }

  add(data: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/role`, data) as Observable<any>;
  }

  detail(id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/role/${id}`) as Observable<any>;
  }

  listAccount(id: string, data: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/account/${id}`, data) as Observable<any>;
  }

  listDefaultRole(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/role_base`) as Observable<any>;
  }
  
  deleteRow(id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/role/delete/${id}`) as Observable<any>;
  }
  
}

