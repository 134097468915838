export interface IInventory {
    code?: string;
    name?: string;
    description?: string;
    status?: number;
    rent_from?: Date;
    rent_to?: Date;
    type?: string;
    id?: string;
    [key: string]: any;
}