import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../common/http.service';

@Injectable({
	providedIn: 'root',
})
export class IcomLibsProjectIamAccountService {
	constructor(private apiService: ApiService) { }

	add(data: any): Observable<any> {
		return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/cms/user`, data) as Observable<any>;
	}

	list(data: any): Observable<any> {
		return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixIam}/v1/account/users`, data) as Observable<any>;
	}

	adminHistories(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixProduct}/v1.2/admin-histories`;
    return this.apiService.get(url, body) as Observable<any>;
  }

	getActivityActions() {
    const url = `${environment.apiGatewayUrl}${environment.suffixProduct}/v1.2/admin-histories/list-feature`;
    return this.apiService.get(url) as Observable<any>;
  }

	getActivityParams(id: string) {
    const url = `${environment.apiGatewayUrl}${environment.suffixProduct}/v1.2/admin-histories/list-action/${id}`;
    return this.apiService.get(url) as Observable<any>;
  }

}
