import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzSelectModule } from 'ng-zorro-antd/select';

@Component({
  selector: 'formly-field-drawer-link',
  standalone: true,
  imports: [
    NzSelectModule,
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule,
    NzDrawerModule
  ],
  template: `
    <a class="font-sm" (click)="openDrawer()">{{ 'Xem chi tiết'}}</a>
    <nz-drawer [nzVisible]="visible" (nzOnClose)="visible = false" [nzWidth]="700">
      <div *nzDrawerContent [innerHTML]="formControl.value"></div>
    </nz-drawer>
`,
})
export class FormlyFieldDrawerLinkComponent extends FieldType {
  visible: boolean = false;

  openDrawer(): void {
    this.visible = true;
  }

  closeDrawer(): void {
    this.visible = false;
  }
}