import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchComponent, NzSwitchModule } from 'ng-zorro-antd/switch';

@Component({
  selector: 'formly-field-switch',
  template: `
    <nz-form-item>
      <nz-form-control [nzSpan]="controlSpan">
        <nz-switch
          [formControl]="formControl"
          [formlyAttributes]="field"
          [nzDisabled]="to.disabled || to.readonly">
        </nz-switch>
      </nz-form-control>
    </nz-form-item>
  `,
  standalone: true,
  imports: [
    NzRadioModule,
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    NzInputModule,
    AsyncPipe,
    NzSwitchModule
  ]
})
export class FormlyFieldSwitch extends FieldType {
  
  get labelSpan() {
    return this.to.labelSpan || 6;
  }

  get controlSpan() {
    return this.to.controlSpan || 18;
  }
}