export interface ICampaign {
    id?: number,
    sku?: string;
    name?: string;
    type?: string;
    status?: number;
    show_order?: number;
    start_time?: string;
    end_time?: string;
    catalog?: string;
    gen_code_type?: string;
    brand_id?: string;
    unlimit?: boolean;
    store_ids?: Array<number>;
    organization_id?: number;
    general?: ICampaignGeneral;
    transaction_tags?: Array<ICampaignTransactionTag>;
    customer_profile_criteria?: ICampaignCustomerProfileCriteria;
    reference_customer_profile_criteria?: ICampaignCustomerProfileCriteria;
    balance_pool_criteria?: ICampaignBalancePoolCriteria;
}

export interface ICampaignTransaction {
    id?: number,
    working_site_id?: number;
    campaign_id?: number;
    campaign_name?: string;
    customer_id?: number;
    customer_phone?: string;
    ref_customer_id?: number;
    ref_customer_phone?: string;
    gid?: string;
    formula_lucky_draw?: number;
    formula_gift_box?: string;
    total_award_point?: number;
    reward_product?: string;
    reason?: string;
    status?: string;
    formula1?: number;
    formula2?: number;
    formula3?: number;
    formula4?: number;
    formula5?: number;
    formula_reference_customer?: number;
    formula6?: string;
    formula_reference_customer_product?: string;
    campaign_transaction_info?: string;
    create_time?: string;
    update_time?: string;
    dtm_branches?: Array<ICampaignTransactionBranch>
}

export interface ICampaignTransactionBranch {
    id?: number,
    gid?: string;
    url?: string;
    data?: string;
    branch_id?: string;
    op?: string;
    status?: string;
    create_time?: string;
    update_time?: string;
    finish_time?: string;
    rollback_time?: string;
}

export interface ICampaignGeneral {
  campaign_model: string;
  campaign_name: string;
  campaign_description: string;
  pool_id: number
  sub_pool_id: number;
  start_date: string;
  end_date: string;
  membership_campaign_status: string;
  message_template_id?: number;
  message_template_id_2?: number;
  reversal_message_template_id?: number;
  transaction_by_date?: string;
  id?: string;
}

export interface ICampaignTransactionTag {
  transaction_tag_id?: number;
  execution_sequence?: number;
  working_site_id?: number;
  campaign_id?: number;
  id?: number;
}

export interface ICampaignBalancePoolCriteria {
  pool_id?: number;
  pool_must_be_x?: string;
  pool_must_be_x_value?: number;
  working_site_id?: number;
  campaign_id?: number;
  id?: number;
}

export interface ICampaignCustomerProfileCriteria {
  include_gender?: string;
  effective_on_date_of_birth?: boolean;
  effective_on_month_of_birth?: boolean;
  from_home_partner?: string;
  exclude_home_partner?: boolean;
  nationality?: string;
  birth_country?: string;
  include_occupation?: string;
  customer_age_min?: number;
  customer_age_max?: number;
  membership_age_min?: number;
  membership_age_max?: number;
  customer_status?: string;
  customer_status_exclude?: boolean;
  customer_ids?: string;
  working_site_id?: number;
  campaign_id?: number;
  id?: number;
}

export interface ICampaignLanguage {
    name: string;
    language: string;
    detail: string;
    term_and_condition: string
    campaign_id?: number;
    id?: string;
}

export interface ICampaignMedia {
    name: string;
    type: string;
    campaign_id?: number;
    id?: string;
    url: string;
}


export interface ICampaignPrice {
    id?: string;
    payment_method?: string;
    purchase_cost?: number;
    sale_price?: number;
    campaign_id?: string;
    pool_id?: number;
    sub_pool_id?: number;
}


export interface ICampaignDiscount {
    id?: string;
    campaign_id?: string;
    type?: string;
    value?: number;
    status?: number;
    valid_from?: Date;
    valid_until?: Date;
    minimum_order_value: number;
    maximum_discount_amount: number;
}


export interface ICampaignConfig {
    id?: string;
    campaign_id?: number;
    type?: string;
    value?: string;
    name: string;
}
