import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DefineKey } from '@icom/defines';
import { IIcomLibsCoreAction, IIcomLibsCoreModuleFunction } from '../../components/login/src/login/interfaces/login.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private functions: IIcomLibsCoreModuleFunction[];
  private permission!: any;

  constructor(private notificationService: NzNotificationService,
    private router: Router) {
    const localStorageService = inject(LocalStorageService);
    this.functions = localStorageService.getItem(DefineKey.FUNCTIONS) || [];
    const actions = this.functions.filter(m => location.pathname.includes(m.path || ''));
    if (!actions) {
      this.showNotification('No action found');
      this.permission = {};
    }

    this.permission = actions?.reduce((acc, action) => {
      action.code &&   ((acc as any)[action.code] = action.selected || false);
      return acc;
    }, {}) || {};
  }


  private showNotification(message: string): void {
    this.notificationService.error('Permission Denied', message);
  }

  getPermission(key: string): boolean {
    return true;
  }
}
