import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { environment } from 'libs/core/environments/environment';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    const token = localStorage.getItem('token');

    if (!token) {
      if (url !== '/login') {
        this.router.navigate(['/login']);
        localStorage.removeItem('token');
      }
      return of(false);
    }
    try {
      let role = jwt_decode<any>(token).role ;
      if(environment.module === 'ep') {
        role = 'cms_admin';
      }
      const isAuthorized = role === 'cms_admin' || role === 'root_user' ;
      if (!isAuthorized) {
        // Redirect only if the current URL is not the login page
        if (url !== '/login') {
          this.router.navigate(['/login']);
          localStorage.removeItem('token');
        }
        return of(false);
      }
      return of(true);
    } catch (error) {
      if (url !== '/login') {
        this.router.navigate(['/login']);
        localStorage.removeItem('token');
      }
      return of(false);
    }
  }
}
