import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  selector: 'formly-field-input-custom',
  template: `
    <div class="custom-input-container">
      <ng-container *ngIf="!to.readonly; else readonlyTemplate">
        <nz-input-group [nzSuffix]="suffixTemplateInfo">
          <input nz-input
            class="max-w-md"
            [ngClass]="{'!w-[150px]':  props.type === 'number'}"
            [formControl]="$any(formControl)"
            [formlyAttributes]="field"
            [type]="props.type || 'text'"
            [max]="props.maxLength || 99999999"
            [placeholder]="props.placeholder || 'Nhập nội dung'"
            (keydown)="preventInput($event)"
            (input)="handleInput($event)" />
        </nz-input-group>
 
        <span class="input-addon">{{ to.addonText }}</span>
      </ng-container>

      <ng-template #suffixTemplateInfo>
        <span style="    position: absolute;
    right: 4px;
    top: -16px;" *ngIf="props.tooltip" nz-icon nz-tooltip [nzTooltipTitle]="props.tooltip" nzType="info-circle"></span>
      </ng-template>
      <ng-template #readonlyTemplate>
        <span>{{ formControl.value }}</span>
        <span class="input-addon ml-4">{{ to.addonText }}</span>
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
    .custom-input-container {
      display: flex;
      align-items: center;
    }
    .input-addon {
      margin-left: 8px;
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzInputModule,
    FormsModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgClass,
    NzToolTipModule,
    NzIconModule
  ]
})
export class FormlyFieldCustomInputComponent extends FieldType<FieldTypeConfig> {
  handleInput(event: any) {
    // if (this.props.maxLength && event.target.value.length > this.props.maxLength) {
    //   event.preventDefault();
    //   return;
    // }
    if (this.props.type === 'number') {
      const value = parseFloat(event.target.value);
      this.formControl.setValue(Number.isNaN(value) ? null : value);
    }
  }

  preventInput(event: KeyboardEvent) {
    // Cho phép các phím điều hướng và điều khiển
    if (event.key === 'Enter') {
      // Nếu phím nhấn là Enter, ngăn chặn sự kiện
      event.preventDefault();
      return;
    }

  }
}
