import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IcomLibsProjectLoginService } from '../projects/iam/login.service';
import { IComMessageService } from '../common';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	constructor(
		private authService: IcomLibsProjectLoginService,
		private iComMessageService: IComMessageService
		) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error) => {
				return this.errorHandler(error);
			})
		);
	}

	private errorHandler(errorResponse: HttpErrorResponse): Observable<HttpEvent<unknown>> {
		if (errorResponse instanceof HttpErrorResponse) {
			if (errorResponse.status === HttpStatusCode.Unauthorized) {
				this.authService.logout();
			}
		}
		throw errorResponse;
	}
}
