import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RouterStateSnapshot } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IcomLibsCorePathUrlService {
  private currentUrl: string;

  constructor(router: Router) {
    const snapshot: RouterStateSnapshot = router.routerState.snapshot;
    this.currentUrl = snapshot.url;
  }

  getPathUrl(): string {
    return this.currentUrl;
  }
}
