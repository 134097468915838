export interface IFlashSaleInformation {
  stt: number;
  ma: string;
  ten: string;
  tuNgay: string;
  denNgay: string;
  soLuongSanPham: number;
  trangThai: string;
  thoiGianTao: string;
  nguoiTao: string;
}