export interface ISubModuleAction {
  code?: string;
  description?: string;
  nameEn?: string;
  nameVi?: string;
  selected?: boolean;
}

export interface ISubModule {
  actions?: ISubModuleAction[];
  code?: string;
  nameModule?: string;
  parentCode?: string;
  parentName?: string;
  path?: string;
}

export interface IPermissionModule {
  code?: string;
  nameModule?: string;
  subModules?: ISubModule[];
}

export interface IListRole {
  activeCount?: string;
  createdAt?: string;
  createdByName?: string;
  description?: string;
  id?: string;
  inactiveCount?: string;
  name?: string;
  permissions?: IPermissionModule[];
  status?: string;
  updatedAt?: string;
  updatedByName?: string;
}