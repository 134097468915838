import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, DefaultValueAccessor, NG_VALUE_ACCESSOR, FormControlDirective } from '@angular/forms';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { IcomLibsCoreComponentsFormlyDatepicker } from '../datepicker.component';
import { NgClass, NgIf } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';

@Component({
  selector: 'icom-libs-core-components-formly-range_picker',
  templateUrl: './range-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports :[
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    
  ],
  providers: [
    { provide: DefaultValueAccessor, useExisting: IcomLibsCoreComponentsFormlyDatepicker },
    { provide: NG_VALUE_ACCESSOR, useExisting: IcomLibsCoreComponentsFormlyDatepicker, multi: true },
    FormControlDirective // Import và thêm FormControlDirective vào danh sách providers
  ],
})
export class IcomLibsCoreComponentsFormlyRangepicker extends FieldType { 

  ngOnInit() {
    console.log(this.formControl)
  }

  showErr(field: FormlyFieldConfig) {
		return field.formControl && field.formControl.invalid && field.formControl.touched;
	}
}
