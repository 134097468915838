import { Inject, Injectable } from "@angular/core";
import { cloneDeep } from "lodash";

export class PaginationService {
  private pageSize: number = 10;
  private items: any[] = [];
  private currentPage: number = 1;
  private totalPages: number;

  constructor() {
    this.totalPages = this.calculateTotalPages();
  }

  public getCurrentPageItems(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    return this.items?.slice(startIndex < 0 ? 0 : startIndex, (startIndex + this.pageSize) || 10) ;
  }

  public getPageSize(): number {
    return this.pageSize;
  }


 setPageSize(pageSize: number): void {
   this.pageSize = pageSize;
   this.totalPages = this.calculateTotalPages();
 }

  public getTotalPages(): number {
    return this.totalPages;
  }

  public setItems(data: any) {
    this.items.splice(0, this.items.length);
    this.items.push(...data);
  }

  public resetItems() {
    this.items.length = 0;
  }

  public getItems() {
    return this.items;
  }

  public addItem(item: any): void {
    this.items?.unshift(item);
  }

  public removeItem(item: any): void {
    const index = this.items?.findIndex((existingItem) => (existingItem.id && item.id) ? (existingItem.id === item.id) : (existingItem.product_id === item.product_id));
  if (index > -1) {
      this.items?.splice(index, 1);
      this.recalculatePagination();
    }
  }

  itemExists(item: any): boolean {
    return this.items?.some((existingItem) => (existingItem.id && item.id) ? (existingItem.id === item.id) : (existingItem.product_id === item.product_id));
  }

  findPageIndex(item: any): number {
    const index = this.items?.findIndex(existingItem => existingItem.id === item.id);
    return Math.floor(index / this.pageSize);
  }

  public setCurrentPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  public getCurrentPage() {
    return this.currentPage;
  }

  private calculateTotalPages(): number {
    return Math.ceil(this.items?.length / this.pageSize);
  }

  public recalculatePagination(): void {
    this.totalPages = this.calculateTotalPages();
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
  }
}
