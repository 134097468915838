import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ApiService } from "../../common/http.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesRetailService {
  constructor(private apiService: ApiService) { }

  createRetail(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management-retail`, body);
  }

  updateRetail(body = {}) {
    return this.apiService.put(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management-retail`, body);
  }

  getRetails(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management-retails`, params)
  }

  getRetailById(id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management-retail/${id}`)
  }

  getListVoucherProductOfRetail(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/list-product-retail`, params)
  }

  getPrice(params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2/management-price`, params)
  }

  updateStatusVoucherOfRetail(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixProduct}v1.2//submit-product-retail`, body);
  } 
}
