import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NgIf } from '@angular/common';

@Component({
  selector: 'icom-libs-core-components-formly-wrapper_panel',
  template: `
    <nz-space nzDirection="vertical" class="w-full flex" [nzSize]="'small'">
      <nz-card  *nzSpaceItem [nzTitle]="props.label ? titleTemplate: ''" [nzSize]="'small'" class="custom-card">
        <ng-container #fieldComponent></ng-container>
      </nz-card>

      <ng-template #titleTemplate>
        <span *ngIf="props.label" class="font-bold text-sm">{{ props.label }}</span>
      </ng-template>
    </nz-space>
  `,
  styles: [`
    .custom-card {
      border: 1px solid #d9d9d9; /* Màu border */
      border-radius: 8px; /* Bo góc */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Bóng đổ */
    }
  `],
  standalone: true,
  imports: [NzSpaceModule, NzCardModule, NgIf],
})
export class IcomLibsCoreComponentsFormlyPanelWrapperComponent extends FieldWrapper {}
