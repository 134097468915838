import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, finalize, map } from 'rxjs';
import { IUserLogin } from '../../../interfaces/projects/iam/login.interface';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../authenticate/local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class IcomLibsProjectLoginService {
	constructor(private http: HttpClient, private readonly router: Router, private localStorageService: LocalStorageService) { }

	login(body: IUserLogin) {
		return this.http.post(`${environment.apiGatewayUrl}${environment.suffixIam}v1/authentication/login`, body);
	}

	loginMerchant(body: IUserLogin) {
		return this.http.post(`${environment.merchantUrl}oauth/token`, body, {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': 'Basic MTIzOjEyMw==',
			})
		}).pipe(finalize(() => { }));
	}

	logout() {
		this.localStorageService.removeItem("token");
		this.localStorageService.removeItem("merchantToken");
		window.location.href = '/login'
	}

	getRefreshToken(refreshToken: string) {
		const body = `grant_type=refresh_token&client_id=&refresh_token=${refreshToken}
        &client_secret=`;
		return this.http.post(`${environment.apiGatewayUrl}gup2start/rest/iam/v1/authentication/login`, body, {
			headers: new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			})
		}).pipe(map((result: any) => {
			return result.refresh_token;
		})) as Observable<string>;
	}
}
