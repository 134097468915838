// time-picker.type.ts
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import * as dayjs from 'dayjs';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';

@Component({
  selector: 'formly-field-time-picker',
  template: `
    <nz-time-picker
      [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [nzAllowEmpty]="props.nzAllowEmpty"
      [nzDefaultOpenValue]="props.nzDefaultOpenValue"
      [nzDisabled]="props.disabled"
      [nzSize]="props.nzSize"
      [nzFormat]="props.nzFormat"
      [nzPlaceHolder]="'HH:mm'"
      (ngModelChange)="props.changes && props.changes($event, field)"
    ></nz-time-picker>
  `,
  standalone: true,
  imports: [
    NzTimePickerModule,
    CommonModule,
    ReactiveFormsModule,
    NzInputModule,
    FormsModule,
    FormlyModule,
    FormlyNgZorroAntdModule
]

})
export class FormlyFieldTimePicker extends FieldType {
 protected parseStrategy: string;
  constructor() {
    super();
    this.parseStrategy =  'HH:mm';
  }

  defaultParsers = {
    time: (value: any) => {
      if (value instanceof Date) {
        return value;
      }

      if (typeof value === 'string') {
        if (value.trim() === '') {
          return null;
        }
        return dayjs(value, this.parseStrategy).toDate();
      }

      return null;
    },
  };


  defaultFormatters = {
    time: (value: Date) => {
      if (value) {
        return dayjs(value).format(this.parseStrategy);
      }
      return '';
    },
  };
}