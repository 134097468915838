import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IcomLibsDynamicComponentService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {}

  appendComponentToBody(component: any): ComponentRef<any> {
    // 1. Tạo factory cho component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    // 2. Tạo component instance
    const componentRef = componentFactory.create(this.injector);

    // 3. Gắn component vào DOM
    this.appRef.attachView(componentRef.hostView);

    // 4. Lấy DOM element từ component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 5. Thêm DOM element vào body
    document.body.appendChild(domElem);

    // 6. Trả về ComponentRef để có thể thao tác với component sau này
    return componentRef;
  }
  
  appendComponentToComponent(targetComponent: any, componentToAdd: any): ComponentRef<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentToAdd);
    const viewContainerRef = targetComponent.viewContainerRef as ViewContainerRef;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    return componentRef;
  }

  removeComponentFromBody(componentRef: ComponentRef<any>): void {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
removeComponentFromComponent(targetComponent: any, componentRef: ComponentRef<any>): void {
  // Assuming targetComponent has a viewContainerRef property that is a ViewContainerRef
  const viewContainerRef = targetComponent.viewContainerRef as ViewContainerRef;

  // Detach the view from the view container
  const index = viewContainerRef.indexOf(componentRef.hostView);
  if (index !== -1) {
    viewContainerRef.detach(index);
  }

  // Destroy the component
  componentRef.destroy();
}
}