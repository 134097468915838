import { Injectable } from "@angular/core";
import { environment } from "libs/core/environments/environment";
import { Observable } from "rxjs";
import { ApiService } from "../../common/http.service";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectOrderService {
  constructor(private apiService: ApiService) { }

  detailTransaction(id: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixOrder}orders/${id}`);
  }

  listTransaction(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixOrder}orders`, body);
  }

  retryTransaction(id: string): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixOrder}/retry`, { gid: id });
  }

  forceStopTransaction(orderId: string): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixOrder}orders/${orderId}/force-stop`);
  }

  getStatusTransactionOrder(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixOrder}status`);
  }

  getStatusTransactionTypes(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixOrder}types`);
  }

  updateTransaction(orderId: string, body: any): Observable<any> {
    return this.apiService.patch(`${environment.apiGatewayUrl}${environment.suffixOrder}orders/${orderId}`);
  }

  getPaymentMethod(): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixOrder}payment-methods`);
  }

  getTransactionConditions() {
    const url = `${environment.apiGatewayUrl}${environment.suffixOrder}transaction-conditions`;
    return this.apiService.get(url) as Observable<any>;
  }

  postTransactionConditions(body: any) {
    const url = `${environment.apiGatewayUrl}${environment.suffixOrder}transaction-conditions`;
    return this.apiService.post(url, body) as Observable<any>;
  }

  getPaymentMethods() {
    const url = `${environment.apiGatewayUrl}${environment.suffixOrder}payment-methods`;
    return this.apiService.get(url) as Observable<any>;
  }

  getPaymentConfig(): Observable<any>  {
    const url = `${environment.apiGatewayUrl}${environment.suffixOrder}payment-config/FIRST_PAYMENT`;
    return this.apiService.get(url) as Observable<any>;
  }

  createPaymentConfig(body: any): Observable<any>  {
    const url = `${environment.apiGatewayUrl}${environment.suffixOrder}payment-config`;
    return this.apiService.post(url, body) as Observable<any>;
  }

  updatePaymentConfig(body: any): Observable<any>  {
    const url = `${environment.apiGatewayUrl}${environment.suffixOrder}payment-config`;
    return this.apiService.post(url, body) as Observable<any>;
  }
}

