import { NgIf, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
@Component({
  selector: 'formly-multi-checkbox-field',
  template: `
    <nz-form-item>
       <nz-form-control>
        <nz-checkbox-group
          [nzDisabled]="to.disabled"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
          [ngModel]="props.options"
          (ngModelChange)="to.change && to.change(field, $event?.[0]?.checked)">
        </nz-checkbox-group>
      </nz-form-control>
    </nz-form-item>
  `,
  standalone: true,
  imports: [
    NzCheckboxModule,
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyModule,
    NgIf,
    NgClass
  ]
})
export class MultiCheckboxTypeComponent extends FieldType implements OnInit {

  ngOnInit() {
    this.props.options = (this.props.options as any[])?.map(option => ({
      ...option,
      disabled: this.props.disabled2,
      checked: this.props.value2
    }));
    if (this.props.change) {
      this.props.change(this.field, this.props.value2);
    }
  }
}
