export interface DiscountCouponRelease {
  id: string;
  sponsor: Sponsor;
  sponsorship_program: string;
  code: string;
  name: string;
  start_time: string;
  end_time: string;
  thumbnail: string;
  description: string;
  status: string;
  quantity_coupon_code: number;
  cnt_max_used: MaxUsage;
  cnt_max_per_user: MaxUsage;
  order_value_limit: number;
  discount_value: DiscountValue;
  creator_info: CreatorInfo;
  updater_info: string | null;
  apply_for: ApplyFor;
  apply_condition: ApplyCondition;
  caculated_discount_value: number;
  created_at: string;
  updated_at: string;
}

interface Sponsor {
  id: string;
  title: string;
}

interface MaxUsage {
  value: number;
  unlimit: boolean;
}

interface DiscountValue {
  unit: string;
  value: number;
  max_value: number;
}

interface CreatorInfo {
  working_site_id: number;
  worker_site_id: number;
  name: string;
}

interface ApplyFor {
  type: string;
  value: ApplyValue[];
  auto_apply: boolean;
}

interface ApplyValue {
  working_site_id: number;
  worker_site_id: number;
  name: string;
}

interface ApplyCondition {
  category: string;
  category_value: string;
  brands: string | null;
  items: Item[];
}

interface Item {
  id: number;
  name: string;
  price: string;
  brand_id: string | null;
}

interface DiscountCoupon {
  phone_number: string;
  worker_site_id: number;
  code: string;
  discount_coupon_code_id: number;
  coupon_release_id: string;
  discount_coupon_release: DiscountCouponRelease;
  cnt_max_per_user: number;
  cnt_user_used: number;
  cnt_used: number;
  creator_site_id: number;
  creator_info: string;
  status: string;
}
