import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-table-input',
  template: `
    <ng-container *ngIf="!readonly; else readonlyTemplate">
      <ng-container *ngIf="type === 'number'; else textInput">
        <nz-input-number
          class="!w-full"
          [formControl]="inputControl"
          [nzPlaceHolder]="placeholder || 'Nhập số'"
          [nzFormatter]="numberFormatter"
          [nzParser]="numberParser"
          (keydown)="preventInput($event)">
        </nz-input-number>
      </ng-container>
      <ng-template #textInput>
        <input
          nz-input
          [formControl]="inputControl"
          [type]="type"
          [placeholder]="placeholder || 'Nhập số'" />
      </ng-template>
      <div *ngIf="inputControl?.invalid && (inputControl?.dirty || inputControl?.touched)" class="mt-1">
        <small class="text-red-600 text-xs" *ngIf="inputControl.errors?.['required']">Trường này là bắt buộc</small>
        <small class="text-red-600 text-xs" *ngIf="inputControl.errors?.['min']">Giá trị tối thiểu là {{ min }}</small>
        <small class="text-red-600 text-xs" *ngIf="inputControl.errors?.['max']">Giá trị tối đa là {{ max }}</small>
      </div>
    </ng-container>
    <ng-template #readonlyTemplate>
      <span>{{ value || '' }}</span>
    </ng-template>
  `,
  styles: [],
  standalone: true,
  imports: [
    NzInputModule,
    NzInputNumberModule,
    ReactiveFormsModule,
    NgIf
  ]
})
export class TableInputComponent implements OnInit {
  protected inputControl!: FormControl;

  @Input() type: 'text' | 'number' = 'number';
  @Input() value: any;
  @Input() readonly!: boolean | undefined;
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() max: number | undefined;
  @Input() min: number | undefined;
  @Input() dataRow: any;
  @Input() props: any;

  @Output() valueChange = new EventEmitter<number>();

  ngOnInit() {
    const validators: any[] = [];
    if (this.required) validators.push(Validators.required);
    if (this.min !== undefined) validators.push(Validators.min(this.min || 1));
    if (this.max !== undefined) validators.push(Validators.max(this.max || 99999999));
  
    // Tạo FormControl với giá trị ban đầu đã được format
    this.inputControl = new FormControl((this.parseNumber(this.value)), validators);
  
    this.inputControl.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      const numericValue = this.parseNumber(value);
      // Cập nhật lại giá trị cho FormControl với giá trị đã format, nhưng không phát sinh sự kiện valueChanges mới
      this.inputControl.updateValueAndValidity();

      // this.inputControl.setValue(this.numberFormatter(numericValue), { emitEvent: false });
      // Phát sinh sự kiện valueChange với giá trị số (không có dấu phẩy)
      this.valueChange.emit(numericValue);
    });
  
    this.props && this.props.functionCheckReadonly && (this.readonly = this.props.functionCheckReadonly(this.dataRow));
  }
  
  
  save() {
    this.inputControl.markAsTouched();
    return { valid: !this.readonly ? this.inputControl.valid : true, dataRow: this.dataRow };
  }

  numberFormatter(value: number): string {
    // Chuyển đổi giá trị số thành định dạng có dấu phẩy ngăn cách hàng nghìn
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  numberParser(value: string): string {
    return value.replace(/,/g, '');
  }

  parseNumber(value: string): any {
    // Loại bỏ dấu phẩy và chuyển đổi sang số
    return value && parseInt(value.toString().replace(/,/g, ''), 10) || '';
  }

  preventInput(event: KeyboardEvent) {

    // Cho phép các phím điều hướng và điều khiển
    const allowedKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Tab', 'Delete', 'End', 'Home'];
    if (event.key === 'Enter') {
      // Nếu phím nhấn là Enter, ngăn chặn sự kiện
      event.preventDefault();
      return;
    }
 
    if (allowedKeys.includes(event.key)) {
      return; // Cho phép các phím điều hướng và điều khiển
    }
  
    if (this.inputControl?.value?.toString()?.length > 16) {
      // Nếu phím nhấn là Enter, ngăn chặn sự kiện
      event.preventDefault();
      return;
    }

    
    const char = event.key;
    const regex = /^[0-9]$/; // Chỉ cho phép số từ 0 đến 9
  
    if (!regex.test(char)) {
      event.preventDefault(); // Ngăn chặn nhập ký tự không phải số
    }
  }
}
