import { Injectable } from "@angular/core";
import { IInventory } from "@icom/interfaces";
import { environment } from "../../../environments/environment";
import { Observable, map } from "rxjs";
import { ApiService } from "../../common/http.service";
import { HttpHeaders } from "@angular/common/http";


@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesInventoryService {
  constructor(private apiService: ApiService) { }

  listInventory(body = {}): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories`, body);
  }

  listInventoryFlashSale(body = {}): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories-open`, body);
  }

  createInventory(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories`, body);
  }

  updateInventory(id?: string, body = {}) {
    return this.apiService.put(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/${id}`, body);
  }

  getInventory(id?: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/${id}`);
  }

  searchInventory(): Observable<any> {
    const params = { page: 1, limit: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories`, params) as Observable<any>;
  }

  import(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventory-product-items/import`, body);
  }

  save(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventory-product-items`, body);
  }


  searchProductInventory(productId: number | string): Observable<any> {
    const params = { page: 1, limit: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/products/${productId}/inventories`, params) as Observable<any>;
  }

  deleteInventory(id: string | number) {
    return this.apiService.delete(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/${id}`);
  }

  getListProductOfInventory(inventoryId: string | number, params?: any): Observable<any> {
    const paramsDefault = { page: 1, limit: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0//inventory/${inventoryId}/products`,params || paramsDefault);
  }


  getListProductFlashSaleOfInventory(inventoryId: string | number, params?: any): Observable<any> {
    const paramsDefault = { page: 1, limit: 99999 };
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0//inventory/${inventoryId}/products-flashsale`,params || paramsDefault);
  }


  getListProductsInventories(body: any, params: any): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventory/${params.inventory_id}/products/${params.product_id}/items`, body);
  }

  uploadFile(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.2/resource/file/csv/upload`, body,
      {
        'Content-Type': 'clear'
      }
    );

  }

  createTicket(body: any): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/transfer`, body);
  }

  listTicket(body: any): Observable<any>  {
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/transfer`, body);
  }

  detailTicket(id: any): Observable<any>  {
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/transfer/${id}`);
  }

  deleteTicket(id: string | number) {
    return this.apiService.delete(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/transfer/${id}`);
  }

  updateTicket(id?: string, body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/inventories/transfer/${id}`, body);
  }

  getQuantityProduct(body: any): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/product/inventories/quantity`, body);
  }

  getStatus(): Observable<any>  {
    return this.apiService.get(`${environment.apiGatewayUrl}gup2start/admin/rest/product/api/v1.0/enums/INVENTORY_TRANSFER_TICKET_STATUS`);
  }
}

