export interface IVoucherSelection {
  id: number;
  value: string;
}

export interface IDisplayConfiguration {
  start_time?: string;
  end_time?: string;
  months?: number[];
  days?: number[];
  weekdays?: number[];
  hours?: number[];
  membership_rank?: string[];
  city?: string[];
}

export interface IContentConfiguration {
  no_items?: number;
  status?: IStatusSelection;
  hidden_name?: boolean;
  slider_speed?: number;
}

export interface IStatusSelection {
  name?: string;
  value?: IVoucherSelection[];
}

export interface ISectionConfig {
  id?: number;
  name?: string;
  type?: string;
  working_site_id?: number;
  layout?: string;
  display_conf?: IDisplayConfiguration | null;
  status?: string;
  note?: string;
  order?: number;
  content_conf?: IContentConfiguration | null;
  created_at?: string;
  created_by_id?: number;
  created_by_name?: string;
  update_at?: string | null;
  updated_by_id?: number | null;
  updated_by_name?: string | null;
}
