import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';

@Component({
  selector: 'app-grid-wrapper',
  template: `
    <div class="grid-container">
      <div *ngFor="let field of field.fieldGroup; let i = index" class="grid-item">
        <formly-field [field]="field"></formly-field>
      </div>
    </div>
  `,
  styles: [`.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .grid-item {
    padding: 10px;
  }
  `],
  standalone: true,
  imports: [
    NgFor,
    FormlyModule
  ]
})
export class GridWrapperComponent extends FieldWrapper { }
