import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const EMAIL_REGEX =
  '^(?=.{1,64}@)[A-Za-z0-9\\+_-]+(\\.[A-Za-z0-9\\+_-]+)*@[^-][A-Za-z0-9\\+-]+(\\.[A-Za-z0-9\\+-]+)*(\\.[A-Za-z]{2,})$';

export const PHONE_NUMBER_REGEX =
  '^(\\+\\d{1,3}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$' +
  '|^(\\+\\d{1,3}( )?)?(\\d{3}[ ]?){2}\\d{3}$' +
  '|^(\\+\\d{1,3}( )?)?(\\d{3}[ ]?)(\\d{2}[ ]?){2}\\d{1}$';

export function EmailValidator(control: AbstractControl): ValidationErrors | null {
  return !control?.value?.match(EMAIL_REGEX) ? { email: true } : null;
}

export function PhoneNumberValidator(control: AbstractControl): ValidationErrors | null {
  return !control?.value?.match(PHONE_NUMBER_REGEX) ? { phoneNumber: true } : null;
}

export function MaxValidatorMessageValidator(control: AbstractControl): ValidationErrors | null {
  return (control?.value < 5) ? null : { maxValidatorMessage: true };
}


export function NumberIntMessageValidator(control: AbstractControl): ValidationErrors | null {
  return (control?.value < 0) ? null : { maxValidatorMessage: true };
}



export function MaxUploadFileSizerValidator(control: AbstractControl): ValidationErrors | null {
  const file = control.value;
  if (file) {
    if (file.size > 1024 * 1024) { // 2MB
      return { 'max-upload-file': true };
    }
  }
  return null;
}

export function TypeUploadFileSizerValidator(control: AbstractControl): ValidationErrors | null {
  const file = control.value;
  const fileType = file.type;
  const acceptedFileTypes = ['application/vnd.ms-excel', 'text/csv']; // Excel and CSV MIME types

  if (!acceptedFileTypes.includes(fileType || '')) {
    return { 'type-upload-file': true };
  }
  return null;
};

export function matchingPasswordsValidator(control: AbstractControl): ValidationErrors | null {
  const newPassword = control.get('newPassword')?.value;
  const confirmPassword = control.get('confirmPassword')?.value;
  console.log('vo day');
  // Set or clear the error on the confirmPassword control
  if (newPassword !== confirmPassword) {
    control.get('confirmPassword')?.setErrors({ ...control.get('confirmPassword')?.errors, fieldMatch: true });
    return { fieldMatch: true }; // Return an error for the form group
  } else {
    const errors = control.get('confirmPassword')?.errors;
    if (errors) {
      delete errors['fieldMatch']; // Remove the fieldMatch error
      if (Object.keys(errors).length === 0) {
        control.get('confirmPassword')?.setErrors(null); // Clear errors if no other errors left
      } else {
        control.get('confirmPassword')?.setErrors(errors); // Update the errors object
      }
    }
    return null; // No errors for the form group
  }
}



export const requiredValidationMessage = (err = {}, field: FormlyFieldConfig) => {
  return `Please Enter ${field.templateOptions?.label || field.parent?.templateOptions?.label}`;
};

export const emailValidationMessage = () => `Please Enter Valid Email`;
export const phoneNumberValidationMessage = () => `Please Enter Valid Phone Number`;

export const maxValidatorMessage = (maxError: { max: number; actual: number }) => {
  return `Vui lòng nhập số nhỏ hơn ${maxError['max']}`;
};

export const minValidatorMessage = (minError: { min: number; actual: number }) => {
  return `Vui lòng nhập số lớn hơn ${minError['min']}`;
};

export const maxUploadFileSize = (maxSize: { max: number; actual: number }) => {
  return `Maximum value file is ${maxSize['max']}`;
};

export const noWhitespaceMessage = () => {
  return `Trường này là bắt buộc`;
};

export const uniqueSelection = (name: string) => {
  return `${name} không được trùng nhau.`;
};

export const TypeUploadFileSizerMessage = (maxSize: { max: number; actual: number }) => {
  return `Type Upload File Not Correct`;
};

export const NumberIntMessage = (maxSize: { max: number; actual: number }) => {
  return `Value min is 0`;
};

export function fieldMatchValidator(control: AbstractControl): ValidationErrors | null {
  const { type, value } = control.value;
  if(type === 'PRODUCT_DISCOUNT_TYPE_PERCENT') {
    return (value >= 0 && value < 100) ? null : { fieldMatch: { message: 'Require Value to 0 to 100' } };
  }

  if(type === "PRODUCT_DISCOUNT_TYPE_VALUE") {
    return (value >= 0) ? null :{ fieldMatch: { message: 'Require Value more than 0' } };
  }

  return { maxValidatorMessage: true };
};


export function noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
  // Kiểm tra nếu trường không bắt buộc và giá trị là chuỗi rỗng hoặc chỉ chứa khoảng trắng
  if (!control.value || (typeof control.value === 'string' && !control.value.trim().length)) {
    // Trả về null nếu trường không bắt buộc và rỗng
    return null;
  }

  // Trả về lỗi nếu giá trị chỉ chứa khoảng trắng
  if (typeof control.value === 'string' && !control.value.trim().length) {
    return { noWhitespaceMessage: true };
  }

  return null;
}