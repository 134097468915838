import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatValue',
  standalone: true
})
export class FormatValuePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === null || value === undefined) {
      return '–'; // Ký tự đặc biệt (gạch ngang)
    }

    if (typeof value === 'number') {
      return new Intl.NumberFormat().format(value); // Sử dụng NumberFormat để định dạng số
    }

    return value; // Trả về giá trị không thay đổi nếu không phải số hoặc null/undefined
  }
}
