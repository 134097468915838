
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";
import { ICampaign, ICampaignConfig, ICampaignDiscount, ICampaignPrice, ICampaignTransaction } from "../../../interfaces/projects/campaign/campaign.interface";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectCampaignService {
  constructor(private apiService: ApiService) { }

  listTransaction(body: any): Observable<any>  {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}statistics/transaction/list`, body); 
  }

  detail(id: string) {
    return this.apiService.get(`${environment.apiGatewayUrl}${environment.suffixCampaign}campaign/${id}`);
  }

  createCampaign(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}campaigns/create`, body);
  }

  updateCampaign(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/campaigns/updates`, body);
  }

  getCampaign(CampaignId: number): Observable<ICampaign> {
    return this.apiService.get(`${environment.apiGatewayUrl}/api/v2.0/campaign/${CampaignId}`).pipe(
      map((res: any) => {
        const data = res ? res?.data : null;
        if (!data) return data;
        const response: ICampaign = {
          id: data?.id,
          general: data?.membership_info
        }
        return response;
      })
    );
  }

  getCampaignTransaction(TransactionId: number): Observable<ICampaignTransaction> {
    return this.apiService.get(`${environment.apiGatewayUrl}/api/v2.0/transaction/${TransactionId}`).pipe(
      map((res: any) => {
        return res ? res?.data : null;
      })
    );
  }

  addLanguageCampaign(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/contents`, body);
  }

  updateLanguageCampaign(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/contents/${body.id}`, body);
  }

  // for Campaign media

  addMediaCampaign(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/media`, body);
  }

  updateMediaCampaign(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/media/${body.id}`, body);
  }

  // for Campaign price

  createCampaignPrice(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/prices`, body);
  }

  updateCampaignPrice(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/prices/${body.id}`, body);
  }


  getCampaignPrice(CampaignId?: number): Observable<ICampaignPrice> {
    return this.apiService.get(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/prices/Campaign/${CampaignId}`)
  }

  searchCampaign(): Observable<any> {
    const params = { page: 1, limit: 99999 };
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/campaigns`, params) as Observable<any>;
  }

  // for Campaign discount
  createCampaignDiscount(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/discounts`, body);
  }

  updateCampaignDiscount(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/discounts/${body.id}`, body);
  }


  getCampaignDiscount(CampaignId?: number): Observable<ICampaignDiscount> {
    return this.apiService.get(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/discounts/Campaign/${CampaignId}`)
    // .pipe(
    //     map((res: any) => {
    //         const data = res ? res?.data : null;
    //         if (!data) return data;
    //         const response: ICampaignDiscount = {
    //             id: data?.id,
    //             campaign_id: data?.campaign_id,
    //             type: data?.type,
    //             value: data?.value,
    //             maximum_discount_amount: data?.maximum_discount_amount,
    //             minimum_order_value: data?.minimum_order_value,
    //             status: data?.status,
    //             valid_from: data?.valid_from,
    //             valid_until: data?.valid_until
    //         }
    //         return response;
    //     })
    // );
  }

  // for Campaign config
  createCampaignConfig(body = {}) {
    return this.apiService.post(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/configs`, body);
  }

  updateCampaignConfig(body = {} as any) {
    return this.apiService.put(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/configs/${body.id}`, body);
  }


  getCampaignConfig(CampaignId?: number): Observable<ICampaignConfig> {
    return this.apiService.get(`${environment.apiGatewayUrl}/Campaign/api/v1.0/Campaign/configs/Campaign/${CampaignId}`)
    // .pipe(
    //     map((res: any) => {
    //         const data = res ? res?.data : null;
    //         if (!data) return data;
    //         const response: ICampaignConfig = {
    //             id: data?.id,
    //             campaign_id: data?.campaign_id,
    //             type: data?.type,
    //             value: data?.value,
    //             name: data?.name
    //         }
    //         return response;
    //     })
    // );
  }

  getOptionType(type: string): Observable<any> {
    return this.apiService.get(`${environment.apiGatewayUrl}/Campaign/api/v1.0/enums/${type}`) as Observable<any>;
  }

  getBrand(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.merchantUrl}brand`, params, {
      'isMerchantService': 'yes',
    }) as Observable<any>;
  }

  getStore(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.merchantUrl}store`, params, {
      'isMerchantService': 'yes',
    }) as Observable<any>;
  }

  getOrg(): Observable<any> {
    const params = { page: 1, size: 99999 };
    return this.apiService.get(`${environment.merchantUrl}org`, params, {
      'isMerchantService': 'yes',
    }) as Observable<any>;
  }

  getBrandByOrg(orgId?: string | number): Observable<any> {
    const params = { page: 1, size: 99999, orgId };
    return this.apiService.get(`${environment.merchantUrl}brand`, params, {
      'isMerchantService': 'yes',
    }) as Observable<any>;
  }


  getStoreByBrand(brandId?: string | number): Observable<any> {
    const params = { page: 1, size: 99999, brandId };
    return this.apiService.get(`${environment.merchantUrl}store`, params, {
      'isMerchantService': 'yes',
    }) as Observable<any>;
  }


  getTransactionTags(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/options/transaction-tag`);
  }

  getPool(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/pools`);
  }

  getSubPoolByIdPool(poolId: string): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/subpools`, { pool_id: poolId });
  }

  getMessageTemplate(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/message-templates`);
  }

  deleteInformation(dataDelete: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/campaigns/delete/info`, dataDelete);
  }

  detailTransaction(id: string): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/transaction/detail`, { transaction_id: id });
  }

  retryTransaction(id: string): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/retry`, { gid: id });
  }

  getCountCampaignStatus(): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/campaign-status`);
  }

  batchRewarPreview(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/campaigns/batch-reward/preview`, body, {
      'Content-Type': 'clear'
    });
  }
  
  batchRewardSubmit(body: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaign}/campaigns/batch-reward/submit`, body, {
      'Content-Type': 'clear'
    });
  }
}

