import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ResolveLoginSuccessGuardService implements CanActivate {
	constructor(private route: Router) { }

	canActivate() {
		const token = localStorage.getItem('token') || null;
		const isLogged = token != null ? true : false;
		if (isLogged) {
			this.route.navigate(['/']);
			return false;
		} else {
			return true;
		}
	}
}
