import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Observable, distinctUntilChanged, take } from 'rxjs';

@Component({
  selector: 'app-table-select-multi',
  template: `
    <ng-container *ngIf="!readonly; else readonlyTemplate">
      <nz-select
        class="w-full"
        
        nzMode="multiple"
        [formControl]="selectControl"
        [nzPlaceHolder]="placeholder || 'Chọn nhóm'"
        [nzNotFoundContent]="'Không tìm thấy dữ liệu'"
      >
        <nz-option
          *ngFor="let item of options | async"
          [nzValue]="item.id || item?._id"
          [nzLabel]="item.name"
        ></nz-option>
      </nz-select>
    </ng-container>
    <ng-template #readonlyTemplate>
      <ul class="list-disc pl-5">
        <li *ngFor="let item of selectedOptions">{{ item.name }}</li>
      </ul>
    </ng-template>
  `,
  styles: [],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NzSelectModule],
})
export class TableSelectMultiComponent implements OnInit {
  @Input() options!: Observable<any[]>;
  @Input() value!: any[];
  @Input() placeholder: string | undefined;
  @Input() readonly!: boolean | undefined;
  @Input() required: boolean = false;

  @Output() valueChange = new EventEmitter<any[]>();

  selectControl!: FormControl;
  selectedOptions: { id: any, name: string }[] = [];

  constructor() {

  }

  ngOnInit() {
    this.selectControl = new FormControl(this.value);
    this.selectControl?.valueChanges?.pipe(distinctUntilChanged()).subscribe(value => {
      this.valueChange.emit(value);
      this.selectControl.updateValueAndValidity();
    });
  }

  ngOnChanges(): void {
    if (this.options) {
      this.options.subscribe(options => {
        this.selectedOptions = options.filter(option => this.value.includes(option.id));
      });
    }
  }

  save() {
    this.selectControl.markAsTouched();
    return this.selectControl.valid;
  }

}
