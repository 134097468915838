import { Component, ChangeDetectionStrategy, ViewChild, Type, inject, ChangeDetectorRef, DestroyRef } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyModule,
} from '@ngx-formly/core';
import {
  FormlyFieldProps,
  FormlyNzFormFieldModule,
} from '@ngx-formly/ng-zorro-antd/form-field';
import {
  FormlyFieldSelectProps,
  FormlySelectModule,
} from '@ngx-formly/core/select';
import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, isObservable, switchMap, take, takeUntil, throttleTime } from 'rxjs';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep, isArray } from 'lodash';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'formly-field-nz-select',
  template: `
    <div [class]="props.classInclude" *ngIf="!to.readonly; else readonlyTemplate">
    <nz-select
      [class.ng-dirty]="showError"
      [nzPlaceHolder]="'Chọn giá trị'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [nzServerSearch]="props.nzServerSearch || false"
      [nzShowSearch]="true"
      [nzDisabled]="props.disabled"
      [nzAllowClear]="props.ignoreRemoveIcon ? false : true"
      [nzMode]="props.multiple ? 'multiple' : 'default'"
      (keydown.enter)="handleKeydown($event)"
      [nzDropdownRender]="renderTemplate"
      (nzScrollToBottom)="loadMore()"
      (nzOnSearch)="onSearch($event)"
      (nzOpenChange)="onSelectOpenChange($event)"
      (ngModelChange)="handerChange($event)">
    
    <nz-option
      *ngFor="let item of resolvedOptions"
      nzCustomContent
      [nzValue]="$any(item)?.[props.valueProp || 'value']"
      [nzDisabled]="item.disabled"
      [nzLabel]="item?.[props.labelProp || 'label']">
      
            <span *ngIf="props?.icon" nz-icon [nzType]="props?.icon?.type(field)" nzTheme="twotone"  [nzTooltipTitle]="props?.icon?.message(field)" nz-tooltip [nzTwotoneColor]="'#eb2f96'" class="mr-2"></span>
            {{ item?.[props.labelProp || 'label'] }}
        </nz-option>

    

    <ng-template #renderTemplate>
      <div *ngIf="isLoading" class="loading-container absolute top-5 left-1/2">
        <nz-spin nzSize="large"></nz-spin>
      </div>
    </ng-template>
  </nz-select>
  <span style="    position: absolute;
    right: 4px;
    top: -16px;" *ngIf="props.tooltip" nz-icon nz-tooltip [nzTooltipTitle]="props.tooltip" nzType="info-circle"></span>
      <button *ngIf="props.functionAddNew" nz-button nzType="link" class="add-new-button" (click)="handleAddNew()">
        <span nz-icon nzType="plus" nzTheme="outline"></span> Thêm mới
      </button>

      <ng-container *ngFor="let item of resolvedOptions" class="mt-4">
        <div  *ngIf="item.code === 'PHONE_NUMBER'">
        <label [nzDisabled]="props.disabled" class="text-sm font-semibold" nz-checkbox [ngModel]="item?.['enable_edit']"
        (nzCheckedChange)="props.changeCheckbox && props.changeCheckbox(field, $event)">
          Cho phép sửa {{ item.title }}
        </label>
          </div>
       
      </ng-container>
    </div>
    
    <ng-template #readonlyTemplate>
      <span *ngIf="resolvedOptions && resolvedOptions?.length ">{{ getSelectedLabel(formControl.value) }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    NzInputModule,
    AsyncPipe,
    NzButtonModule,
    NzSpinModule,
    NzIconModule,
    NzCheckboxModule,
    NzToolTipModule
  ],
})
export class FormlyFieldSelect extends FieldType<FieldTypeConfig> {
  resolvedOptions: any[] = [];
  protected isLoading!: boolean;
  private previousValue: any[] = [];
  protected pagination: any = {
    limit: 15,
    page: 1
  };
  searchChange$ = new Subject<any>();

  protected keySearch!: string;
  protected values!: string[];
  private subscription?: Subscription;
  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @ViewChild(NzSelectComponent, { static: false }) nzSelectComponent!: NzSelectComponent;

  handleKeydown(event: any): void {
    if (event.key === 'Enter' && this.nzSelectComponent.nzOpen) {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.searchChange$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((term: string) => {
        this.isLoading = true;
        this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: true }));
        this.cdr.detectChanges();
        this.keySearch = term;
        return this.searchData(term);
      })
    ).subscribe((data: any) => {
      this.isLoading = false;
      this.resolvedOptions = data?.length && !data?.items ? data : data?.items;
      this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
      this.props.firstEmitChange && this.props.change && this.props.change(this.field);
      this.pagination = data?.pagination;
      this.cdr.detectChanges();
    });

    if (isObservable(this.field?.props?.options)) {
      this.subscription = this.field?.props?.options?.pipe().subscribe(options => {
        this.resolvedOptions = options;
        console.log(this.resolvedOptions)
        this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
        this.props.firstEmitChange && this.props.change && this.props.change(this.field);
        this.props?.functionCheckExist && this.props?.functionCheckExist(this.field, this.resolvedOptions);
        this.cdr?.detectChanges();
      });
    } else {
      this.resolvedOptions = this.field?.props?.options as any[];
      this.props?.functionCheckExist && this.props?.functionCheckExist(this.field, this.resolvedOptions);
      this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
      this.props.firstEmitChange && this.props.change && this.props.change(this.field);
      this.cdr?.detectChanges();
    }

    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(currentValue => {
        this.handleOptionSelection(currentValue);
        this.previousValue = currentValue || [];
      });

  }

  protected handerChange(event: any): void {
    this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
    this.props.change && this.props.change(this.field, event);
  }

  isHandlingSelection!: boolean;
  private handleOptionSelection(selectedValues: any[] | any): void {
    if (!selectedValues?.length && !isArray(selectedValues)) {
      return;
    }
    const addedValues = selectedValues?.filter(
      (val: any) => !this.previousValue?.includes(val)
    );
    const removedValues = this.previousValue?.filter(
      (val) => !selectedValues?.includes(val)
    );
    if (!Array.isArray(selectedValues)) {
      selectedValues = [selectedValues];
    }
    this.resolvedOptions?.forEach(option => {
      if (selectedValues?.includes(option[this.props['valueProp']])) {
        if (option?.parent_code) {
          const parentOption = this.resolvedOptions.find(opt => opt.code === option.parent_code);
          if (parentOption) {
            parentOption.checked = true;
            if (!selectedValues.includes(parentOption[this.props['valueProp']])) {
              addedValues?.length && selectedValues?.push(parentOption[this.props['valueProp']]);
              this.formControl.patchValue(selectedValues, { emitEvent: false });
              this.to.readonly = true;
              setTimeout(() => {
                this.to.readonly = false;
                this.cdr.detectChanges();
              }, 50)
              this.cdr.detectChanges();
            }
          }
        }
        this.cdr.detectChanges();
      }
      const childOption = this.resolvedOptions?.find(opt => opt.code === option.child_code);
      if (childOption?.code) {
        removedValues?.length && (selectedValues = selectedValues?.filter((selectedValue: any) => selectedValue !== childOption[this.props['valueProp']]));
        this.formControl.patchValue(selectedValues, { emitEvent: false });
        this.to.readonly = true;
        setTimeout(() => {
          this.to.readonly = false;
          this.cdr.detectChanges();
        }, 50)
        this.cdr.detectChanges();
      }
      this.cdr.detectChanges();
    });
  }

  onSearch(searchText: string) {
    if (!this.props.nzServerSearch) {
      return;
    }
    this.searchChange$.next(searchText);
  }

  private searchData(term: string): Observable<any[]> {
    return this.props.listOptionDropdown(1, { [this.props.keySearch || 'search']: term });
  }


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getSelectedLabel(value: any | any[]): string {
    if (!this.resolvedOptions || value == null) {
      return 'Chưa có dữ liệu';
    }
    if (Array.isArray(value)) {
      if (!value?.length) {
        return 'Chưa có dữ liệu';
      }
      return value
        .map(val => this.getLabelForValue(val))
        .filter(label => label)
        .join(', ');
    }
    console.log(value)

    return this.getLabelForValue(value);
  }

  private getLabelForValue(val: any): string {
    const selectedOption = this.resolvedOptions.find(option => (option[this.props['valueProp']] === val));
    return selectedOption ? (selectedOption[this.props['labelProp']] || selectedOption.value || selectedOption.title || selectedOption.name) : 'Chưa có dữ liệu';
  }

  loadMore(): void {
    if (!this.props.nzServerSearch) {
      return;
    }
    if (this.pagination.total_pages === this.pagination.page) {
      return;
    }
    this.isLoading = true;
    this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: true }));
    this.props.listOptionDropdown(this.pagination.page + 1, { [this.props.keySearch || 'search']: this.keySearch }).subscribe((data: any) => {
      this.isLoading = false;
      this.resolvedOptions = [...this.resolvedOptions, ...data?.items];
      this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: false }));
      this.pagination = data?.pagination;
      this.cdr.detectChanges();
    });
  }

  protected handleAddNew(): void {
    if (this.props.functionAddNew) {
      this.props.functionAddNew(this.field, () => {
        this.ngOnInit();
        this.cdr.detectChanges();
      });
    }
  }

  protected onSelectOpenChange(event: any) {
    // console.log(event);
    if (event && this.props.nzServerSearch) {
      this.onSearch('');
    }
  }
}
