import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiService } from "../../common/http.service";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})

export class IComLibsServicesProjectFlashSaleStatisticService {
  constructor(private apiService: ApiService) { }

  getCountGroupBrand(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/report/count_group_brand`, filter);
  }

  getCountCustomerByCampaign(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/statistic/user`, filter);
  }

  getListMissionByByCampaign(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/statistic/mission-list`, filter);
  }

  getTotalRewardPointGroup(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/statistic/total-rewardpoint-group`, filter);
  }
  
  getListApiStatisticByPathCampaign(apiPath: string, filter?: any): Observable<any> {
    const fullPath = `${environment.apiGatewayUrl}${environment.suffixCampaignV3}/${apiPath}`;
    return this.apiService.post(fullPath, filter);
  }

  downloadFileTransactionDetail(): string {
    return `${environment.apiGatewayUrl}${environment.suffixCampaignV3}/statistic/export/transaction-detail`;
  }

  getVoucher(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/report/buy_flashsale/view_category/success`, filter);
  }

getViewCategorySuccess(filter: any): Observable<any> {
    return this.apiService.post(`${environment.apiGatewayUrl}${environment.suffixCampaignV3}/flashsale/report/buy_flashsale/view_category`, filter);
  }
  
}

