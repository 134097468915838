export interface IUserInfo {
	access_token: string;
	token_type: string;
	expires_at: number;
	refresh_token: string;
	profile?: IUserProfile;
}

export interface IUserProfile {
	name: string;
	preferred_username: string;
	security_stamp: number;
	sub: string;
	tenantid: object;
}

export interface IUserLogin {
	username: string;
	password: string;
	// loginWithNewSession: boolean;
	// clientId: string;
	// scope: string;
}

export interface IAuthEnviroment {
	enviroment: object;
	routerURL: object;
	apiURL: object;
}

export interface IResetPass {
	password: string;
	confirmPassword: string;
	token: string;
}
