import { Directive, Input, ElementRef, Renderer2, HostListener } from "@angular/core";

@Directive({
  selector: '[appShowTooltipOnOverflow]',
  standalone: true
})
export class ShowTooltipOnOverflowDirective {
  @Input() text!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    const el: HTMLElement = this.el.nativeElement;
    if (el.offsetWidth < el.scrollWidth) {
      // The text is overflowing, show the tooltip.
      this.renderer.setAttribute(el, 'nz-tooltip', 'true');
      this.renderer.setAttribute(el, 'nzTooltipTitle', 'ahihi');
    } else {
      this.renderer.removeAttribute(el, 'nz-tooltip');
    }
  }
}