import { NgIf, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';

@Component({
  selector: 'formly-field-nz-color-picker',
  template: `
    <nz-form-item>
      <nz-form-control [nzSm]="14"  [nzXs]="24" [nzErrorTip]="to.error">
        <input nz-input [formControl]="$any(formControl)" [formlyAttributes]="field" type="color" style="width: 80px" />
      </nz-form-control>
    </nz-form-item>
  `,
  standalone: true,
  imports : [
    NzCheckboxModule,
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyModule,
    NgIf,
    NzFormModule,
    NzInputModule,
    NgClass,
  ]
})
export class FormlyFieldColorPickerComponent extends FieldType implements OnInit {
  ngOnInit() {
    if (!this.formControl.value) {
      this.formControl.setValue('#ffffff'); // Default color
    }
  }
}
