import { CommonModule, NgIf, NgClass, AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { FieldType, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Subscription, isObservable } from 'rxjs';
@Component({
  selector: 'formly-field-nz-radio',
  template: `
    <ng-container *ngIf="!to.readonly; else readonlyTemplate">
      <nz-radio-group
        [formControl]="$any(formControl)"
        [nzDisabled]="to.disabled"
      >
        <label
          nz-radio
          *ngFor="
            let option of props.options | formlySelectOptions : field | async
          "
          [nzValue]="option.value"
          [nzDisabled]="option.disabled"
        >
          {{ option.label }}
        </label>
      </nz-radio-group>
    </ng-container>

    <ng-template #readonlyTemplate>
      <span *ngIf="resolvedOptions && resolvedOptions?.length">{{
        getSelectedLabel(formControl.value)
      }}</span>
    </ng-template>
  `,
  standalone: true,
  imports: [
    NzRadioModule,
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    NzInputModule,
    AsyncPipe,
  ],
})
export class FormlyFieldRadio extends FieldType implements OnInit, OnDestroy {
  resolvedOptions: any[] = [];
  private subscription?: Subscription;
  private cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (isObservable(this.field?.props?.options)) {
      this.subscription = this.field?.props?.options?.subscribe((options) => {
        this.resolvedOptions = options;
        this.checkAndSetCheckedOption();
        this.cdr.detectChanges();
      });
    } else {
      this.resolvedOptions = this.field?.props?.options as any[];
      this.checkAndSetCheckedOption();
      this.cdr.detectChanges();
    }
    if (this.props?.required) {
      this.formControl.setValidators(Validators.required);
    }
  }
  
  private checkAndSetCheckedOption() {
    const checkedOption = this.resolvedOptions?.find(option => option?.checked);
    if (checkedOption) {
      !this.form?.value[this.key as string] && this.formControl.setValue(checkedOption.id);
    }
  }
  

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  showErr(field: FormlyFieldConfig) {
    return (
      field.formControl &&
      field.formControl.invalid &&
      field.formControl.touched
    );
  }

  getSelectedLabel(value: any | any[]): string {
    if (!this.resolvedOptions || value == null) {
      return '';
    }

    if (Array.isArray(value)) {
      return value
        .map((val) => this.getLabelForValue(val))
        .filter((label) => label) // Loại bỏ những nhãn không tìm thấy
        .join(', '); // Nối các nhãn bằng dấu phẩy
    }
    return this.getLabelForValue(value);
  }

  private getLabelForValue(val: any): string {
    const selectedOption = this.resolvedOptions.find(
      (option) => (option.id === val)
    );
    // console.log(selectedOption);
    return selectedOption ? selectedOption.value  || selectedOption.label  || selectedOption.name  : 'Chưa có dữ liệu';
  } 
}
