import { CommonModule, JsonPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { cloneDeep } from 'lodash';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import * as dayjs from 'dayjs';

@Component({
  selector: 'formly-array-type',
  template: `
   <nz-form-item class="flex-col" style="flex-direction: column; align-items: start">
   <nz-form-label *ngIf="props.label && props.required" [nzRequired]="true">{{ props.label }}</nz-form-label>
      <nz-form-label *ngIf="props.label && !props.required">{{ props.label }}</nz-form-label>
      <div class="flex justify-start w-full" *ngIf="!props.ignoreAdd">
        <button nz-button
          *ngIf="(!props.readonly && !options?.formState?.disabled && canAdd())"
          nzType="primary"
          (click)="add(undefined,this.dataInit, { markAsDirty: true })">
          <span>{{ props.addText }}</span>
        </button>
      </div>
      <div class="ant-form-item-explain-error" *ngIf="formControl.dirty && formControl.invalid && props.required">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>

      <div *ngIf="!field.fieldGroup?.length" class="flex justify-center w-full my-4">
        <nz-empty [nzNotFoundContent]="props.noDataText || 'No Data'"></nz-empty>
      </div>

      <div *ngFor="let field of field.fieldGroup; let i = index" class="row align-items-start mt-2 w-full flex-col">
          <div class="flex">
          <formly-field class="col w-full" [field]="field"></formly-field>
          <div *ngIf="!props?.ignoreAdd  && (!props.readonly && field.props?.removable !== false && !field.hide)" class="col-2 ml-2 text-right">
            <button nz-button
              [nzType]="'default'" [nzDanger]="true" 
              (click)="remove(i, { markAsDirty: true })">
              <span>{{ 'Xóa' }}</span>
            </button>
          </div>
        </div>
        <div *ngIf="isDuplicateRow(i)" class="w-full ant-form-item-explain-error">
          Hàng này bị trùng lặp với một hàng khác
        </div>
      </div>
    </nz-form-item>
  `,
  standalone: true,
  imports: [
    NzDatePickerModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NgFor,
    NzFormModule,
    NzButtonModule,
    JsonPipe,
    NzEmptyModule,
    CommonModule
  ],
})
export class ArrayTypeComponent extends FieldArrayType implements OnInit {
  dataInit: any;
  duplicateErrors: { [key: string]: number[] } = {};
  duplicateRowIndex: number | null = null;

  ngOnInit() {
    this.formControl.valueChanges.subscribe(values => {
      if (this.props.required) {
        this.formControl.setValidators([Validators.required, this.uniqueObjectValidator()]);
      } else {
        this.formControl.setValidators([this.uniqueObjectValidator()]);
      }
      this.updateDuplicateRowIndex();
    });
    this.dataInit = cloneDeep(this.props.initialModel || undefined);
  }


  updateDuplicateRowIndex() {
    const errors = this.formControl.errors;
    if (errors && errors['duplicateRow'] !== undefined) {
      this.duplicateRowIndex = errors['duplicateRow'];
    } else {
      this.duplicateRowIndex = null;
    }
  }


  updateDuplicateErrors() {
    const errors = this.formControl.errors;
    if (errors && errors['duplicateFields']) {
      this.duplicateErrors = errors['duplicateFields'];
    } else {
      this.duplicateErrors = {};
    }
  }

  hasDuplicates(fieldKey: any, index: number): boolean {
    return this.duplicateErrors[fieldKey.value.key] && this.duplicateErrors[fieldKey.value.key].some(indexes => (indexes as any).includes(index));
  }

  getDuplicateErrorMessage(fieldKey: any): string {
    const duplicateIndexes = this.duplicateErrors[fieldKey.value.key];
    if (duplicateIndexes && duplicateIndexes.length > 0) {
      const positions = duplicateIndexes.map(indexes => (indexes as any).map(i => i + 1).join(', ')).join('; ');
      return `Trường "${fieldKey.value.key}" bị trùng`;
    }
    return '';
  }

  canAdd(): boolean {
    return this.field && this.field.fieldGroup && this.field.fieldGroup.length < (this.props.maxLength || 99999) ? true : false;
  }
  uniqueObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!Array.isArray(value)) {
        return null;
      }
      
      const uniqueObjects = new Set();
      const duplicates = cloneDeep(value).findIndex((obj, index) => {
        if (obj && obj.specific_expect_time_send) {
          obj.specific_expect_time_send = dayjs(obj.specific_expect_time_send).format('HH:mm');
        }
        const stringifiedObj = JSON.stringify(obj);
        if (uniqueObjects.has(stringifiedObj)) {
          return true;
        }
        uniqueObjects.add(stringifiedObj);
        return false;
      });
      
      return duplicates !== -1 ? { 'duplicateRow': duplicates } : null;
    };
  }

  isDuplicateRow(index: number): boolean {
    return this.duplicateRowIndex === index;
  }
}