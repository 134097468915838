import { CommonModule, NgIf, NgClass, AsyncPipe, JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

@Component({
  selector: 'formly-wrapper-tab',
  template: `
    <nz-tabset [formControl]="$any(formControl)"
      [formlyAttributes]="field"
      [nzSelectedIndex]="props.value(field)" 
      (nzSelectChange)="props.changeValue($event, field)">
      <ng-container *ngFor="let tab of field.fieldGroup; let i = index">
        <nz-tab [nzTitle]="tab.props.label">
          <formly-field [field]="tab"></formly-field>
        </nz-tab>
      </ng-container>
    </nz-tabset>
  `,
  standalone: true,
  imports: [
    NzRadioModule,
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgIf,
    NzFormModule,
    NgClass,
    NzInputModule,
    AsyncPipe,
    NzSwitchModule,
    NzTabsModule,
    JsonPipe
  ]
})
export class FormlyWrapperTabComponent extends FieldWrapper implements OnInit {
  ngOnInit() {
    console.log(this.field);
  }
}
