import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LocalStorageService} from './local-storage.service';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	constructor(private localStorageService: LocalStorageService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const hideloader = request.headers.has('hideloader');
		if (hideloader) {
			const headers = request.headers.delete('hideloader');
			request = request.clone({ headers });
		} else {
			// this.loader.showSpinner();
		}
		// add authorization header with basic auth credentials if available
		let token = this.localStorageService.getItem('token') || null;
		const isMerchantService = request.headers.has('isMerchantService');
		if (isMerchantService) {
			token = this.localStorageService.getItem('merchantToken') || null;
		}
		if (token) {
			request = request.clone({
				url: request.url,
				headers: request.headers
					.set('Authorization', 'Bearer ' + `${token}`)
					.delete('isMerchantService', 'yes'),
			});
			if (!request.headers.has('Content-Type')) {
				request = request.clone({
					url: request.url,
					headers: request.headers.set('content-type', 'application/json')
				});
			}
			if (request.headers.get('Content-Type') === 'clear') {
				request = request.clone({
					url: request.url,
					headers: request.headers.delete('Content-Type', 'clear'),
				});
			}
		}
		return next.handle(request).pipe(
			tap((data) => data),
			finalize(() => {
				// this.loader.hideSpinner();
			})
		);
	}
}
