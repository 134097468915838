import { CommonModule, NgIf, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'formly-field-nz-repeat',
  template: `
    <nz-input-group [nzSuffix]="suffixTemplate">
      <input
        nz-input
        [formControl]="formControl"
        [formlyAttributes]="field"
        (keydown.enter)="onEnter($event)"
        (input)="onInput($event)"
      >
    </nz-input-group>
    <ng-template #suffixTemplate>
      <i nz-icon nzType="close-circle" (click)="removeField()"></i>
    </ng-template>
  `,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzInputModule,
    FormsModule,
    NgIf,
    FormlyNgZorroAntdModule,
    FormlyModule,
    NgClass,
    NzToolTipModule,
    NzIconModule
  ],
  standalone: true
})
export class FormlyFieldNzRepeat extends FieldType<FieldTypeConfig> {
  override defaultOptions = {
    templateOptions: {
      placeholder: 'Nhập mã tối thiểu 5 ký tự và tối đa 15 ký tự',
    },
  };

  onEnter(event: Event) {
    event.preventDefault();
    if (this.to.onEnter) {
      this.to.onEnter();
    }
  }

  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }

  removeField() {
    const index = (this.field.parent as any).fieldGroup.indexOf(this.field);
    if (index !== -1) {
      (this.field.parent as any).remove(index);
    }
  }
}