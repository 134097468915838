import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzIconService } from 'ng-zorro-antd/icon';

@Injectable({
  providedIn: 'root'
})
export class IconRegisterService {
  constructor(
    private iconService: NzIconService,
    private http: HttpClient
  ) {}

  loadIcons(iconNames: string[], iconFolderPath: string): void {
    // this.iconService.changeAssetsSource('assets/icons');
    iconNames.forEach((iconName) => {
      this.http.get(`${iconFolderPath}/${iconName}.svg`, { responseType: 'text' }).subscribe(svg => {
        this.iconService.addIconLiteral(iconName, svg);
      });
    });
  }
}
