import { Injectable, inject } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { ISubModuleAction } from '@icom/interfaces';

interface Module {
  sub_modules?: SubModule[];
}

interface SubModule {
  path: string;
  actions: Action[];
}

interface Action {
  selected: boolean;
  id?: string;
}

@Injectable({
  providedIn: 'root'
})



export class AccessGuard implements CanActivateChild {
  private localStorageService = inject(LocalStorageService);
  private modules = this.localStorageService.getItem('permission');

  constructor(private router: Router) { }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const targetUrl = state.url;
    if (targetUrl === '/user/change-password') {
      return true;
    }
    if (targetUrl === '/home') {
      return true;
    }
    if (targetUrl === '/flash-sale/list/group') {
      return true;
    }
    if (targetUrl.includes('/inventory/product')) {
      return true;
    }
    
    const hasAccess = this.checkAccess(targetUrl);

    // if (!hasAccess) {
    //   const redirectUrl = this.findAccessiblePath();
    //   if (redirectUrl) {
    //     this.router.navigate([redirectUrl]);
    //   } else {
    //     this.router.navigate(['/404']); // Chuyển hướng đến trang 404
    //   }
    //   return false;
    // }
    // console.log('vao')
    return true;
  }

  private checkAccess(url: string): boolean {
    if (url.includes('detail')) {
      const baseurl = url.replace('detail', '');

      return this.modules?.some((module: Module) =>
        module.sub_modules?.some((group: SubModule) =>
          baseurl.includes(group.path) &&
          group.actions.some((action: ISubModuleAction) => action.code === 'view' && action.selected)
        )
      ) || false;
    } else {
      return this.modules?.some((module: Module) =>
        module.sub_modules?.some((group: SubModule) =>
          group.path === url &&
          group.actions.some(action => action.selected)
        )
      ) || false;
    }
  }

  private findAccessiblePath(): string | null {
    for (const module of this.modules) {
      for (const group of module.sub_modules) {
        if (group.actions.some((action: any) => action.selected)) {
          return group.path;
        }
      }
    }
    return null;
  }
}
