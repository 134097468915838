export interface IIcomLibsCoreAction {
  description?: string;
  code?: string;
  name_en?: string;
  name_vi?: string;
  selected?: boolean;
  order?: number;
}

export interface IIcomLibsCoreModuleFunction {
  sub_modules?: IIcomLibsCoreModuleFunction[];
  name_en?: string;
  name_vi?: string;
  parent?: string;
  actions?: IIcomLibsCoreAction[];
  path?: string;
  selected?: boolean;
  code?: string;
  active?: boolean;
  order?: number;
}


export interface IIcomLibsCoreMenuChild {
  title?: string;
  path?: string;
  icon?: string;
}

export interface IIcomLibsCoreMenuDefine {
  title?: string;
  icon?: string;
  path?: string;
  open?: boolean;
  children?: IIcomLibsCoreMenuChild[];
}