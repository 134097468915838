import { ColumnMode, TableColumn } from "@swimlane/ngx-datatable";

export interface IPaginationData {
  size?: number;
  number?: number;
  total_items?: number;
  total_pages?: number;
  page?: number;
  total_rows?: number;
  offset?: number;
}

export type TYPE_FORM_CONTROL = any;
export type TYPE_TEMPLATE_REF = any;

export interface IConfigTable {
  loadingIndicator?: boolean;
  columns?: TableColumn[];
  ColumnMode?: ColumnMode;
  headerHeight?: number;
  rowHeight?: number | 'auto' | ((row?: any) => number);
  pageLimit?: number;
}