import { Component, ChangeDetectionStrategy, ViewChild, inject, ChangeDetectorRef, DestroyRef, Input } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyNzFormFieldModule } from '@ngx-formly/ng-zorro-antd/form-field';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AsyncPipe, CommonModule, NgClass, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Observable, Subject, Subscription, debounceTime, distinctUntilChanged, isObservable, switchMap } from 'rxjs';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep, isArray } from 'lodash';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'formly-field-nz-select',
  template: `
    <div [class]="props.classInclude" *ngIf="!to.readonly; else readonlyTemplate">
      <nz-select
        [class.ng-dirty]="showError"
        [nzPlaceHolder]="'Chọn giá trị'"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [nzServerSearch]="props.nzServerSearch || false"
        [nzShowSearch]="true"
        [nzDisabled]="props.disabled"
        [nzMode]="props.multiple ? 'multiple' : 'default'"
        (keydown.enter)="handleKeydown($event)"
        [nzMaxTagCount]="props.maxTagCount"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        [nzDropdownRender]="renderTemplate"
        [nzVirtualScroll]="resolvedOptions.length > 50"
        (nzScrollToBottom)="loadMore()"
        (nzOnSearch)="onSearch($event)"
        (nzOpenChange)="onSelectOpenChange($event)"
        (ngModelChange)="handerChange($event)"
      >
        <nz-option
          *ngFor="let item of resolvedOptions"
          nzCustomContent
          [nzValue]="$any(item)?.[props.valueProp || 'value']"
          [nzDisabled]="item.disabled"
          [nzLabel]="item?.[props.labelProp || 'label']"
        >
          <span
            *ngIf="props?.icon"
            nz-icon
            [nzType]="props?.icon?.type(field)"
            nzTheme="twotone"
            [nzTooltipTitle]="props?.icon?.message(field)"
            nz-tooltip
            [nzTwotoneColor]="'#eb2f96'"
            class="mr-2"
          ></span>
          {{ item?.[props.labelProp || 'label'] }}
        </nz-option>

        <ng-template #renderTemplate>
          <div *ngIf="isLoading" class="loading-container absolute top-5 left-1/2">
            <nz-spin nzSize="large"></nz-spin>
          </div>
        </ng-template>
      </nz-select>
      <!-- Hiển thị số lượng store nổi bật khi totalStores > 50 -->
      <div
        *ngIf="totalStores > 49 && props.multiple"
        class="mt-2 p-2 bg-blue-100 border border-blue-500 rounded-md text-blue-700 font-semibold flex items-center"
      >
        <span nz-icon nzType="shop" nzTheme="outline" class="mr-2"></span>
        {{ totalStores }} stores đang chọn
      </div>
      <span
        style="position: absolute; right: 4px; top: -16px;"
        *ngIf="props.tooltip"
        nz-icon
        nz-tooltip
        [nzTooltipTitle]="props.tooltip"
        nzType="info-circle"
      ></span>
      <button
        *ngIf="props.functionAddNew"
        nz-button
        nzType="link"
        class="add-new-button"
        (click)="handleAddNew()"
      >
        <span nz-icon nzType="plus" nzTheme="outline"></span> Thêm mới
      </button>

      <ng-container *ngFor="let item of resolvedOptions" class="mt-4">
        <div *ngIf="item.code === 'PHONE_NUMBER'">
          <label
            [nzDisabled]="props.disabled"
            class="text-sm font-semibold"
            nz-checkbox
            [ngModel]="item?.['enable_edit']"
            (nzCheckedChange)="props.changeCheckbox && props.changeCheckbox(field, $event)"
          >
            Cho phép sửa {{ item.title }}
          </label>
        </div>
      </ng-container>
    </div>
    <ng-template #tagPlaceHolder let-selectedList> ... </ng-template>
    <ng-template #readonlyTemplate>
      <span *ngIf="resolvedOptions && resolvedOptions?.length">{{ getSelectedLabel(formControl.value) }}</span>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NzSelectModule,
    FormlyNzFormFieldModule,
    FormlySelectModule,
    ScrollingModule,
    FormsModule,
    FormlyModule,
    FormlyNgZorroAntdModule,
    NzFormModule,
    NgIf,
    NzInputModule,
    AsyncPipe,
    NzButtonModule,
    NzSpinModule,
    NzIconModule,
    NzCheckboxModule,
    NzToolTipModule,
  ],
})
export class FormlyFieldSelect extends FieldType<FieldTypeConfig> {
  resolvedOptions: any[] = [];
  protected isLoading!: boolean;
  private previousValue: any[] = [];
  protected pagination: any = { limit: 15, page: 1 };
  searchChange$ = new Subject<any>();
  totalStores: number = 0;

  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private subscription?: Subscription;

  @Input() isStoreMultiSelect: boolean = false;

  @ViewChild(NzSelectComponent, { static: false }) nzSelectComponent!: NzSelectComponent;

  handleKeydown(event: any): void {
    if (event.key === 'Enter' && this.nzSelectComponent.nzOpen) {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.searchChange$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((term: string) => {
          this.isLoading = true;
          this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: true }));
          this.cdr.detectChanges();
          this.keySearch = term;
          return this.searchData(term);
        })
      )
      .subscribe((data: any) => {
        this.isLoading = false;
        this.resolvedOptions = data?.length && !data?.items ? data : data?.items;
        this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
        this.props.firstEmitChange && this.props.change && this.props.change(this.field);
        this.pagination = data?.pagination;
        this.cdr.detectChanges();
      });

    if (isObservable(this.field?.props?.options)) {
      this.subscription = this.field?.props?.options?.subscribe(options => {
        this.resolvedOptions = options;
        this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
        this.props.firstEmitChange && this.props.change && this.props.change(this.field);
        this.cdr?.detectChanges();
      });
    } else {
      this.resolvedOptions = this.field?.props?.options as any[];
      this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
      this.props.firstEmitChange && this.props.change && this.props.change(this.field);
      this.cdr?.detectChanges();
    }

    // Khởi tạo totalStores từ giá trị ban đầu của formControl
    this.updateTotalStores(this.formControl.value);

    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe(currentValue => {
        this.handleOptionSelection(currentValue);
        this.previousValue = currentValue || [];
        this.updateTotalStores(currentValue); // Cập nhật totalStores khi giá trị thay đổi
        this.cdr.detectChanges();
      });
  }

  // Hàm cập nhật totalStores dựa trên formControl.value
  private updateTotalStores(value: any): void {
    if (Array.isArray(value)) {
      this.totalStores = value.length; // Đếm số phần tử đang chọn
    } else {
      this.totalStores = value ? 1 : 0; // Trường hợp single select
    }
  }

  protected handerChange(event: any): void {
    this.props.emitList && this.props.emitList(this.resolvedOptions, this.field);
    this.props.change && this.props.change(this.field, event);
    this.updateTotalStores(event);
    this.cdr.detectChanges();
  }

  // private updateTotalStores(value: any): void {
  //   if (Array.isArray(value)) {
  //     this.totalStores = value.length;
  //     // Cập nhật model với các giá trị đã chọn
  //     this.field.model[this.field.key as string] = value;
  //   } else {
  //     this.totalStores = value ? 1 : 0;
  //     this.field.model[this.field.key as string] = value ? [value] : [];
  //   }
  // }

  protected keySearch!: string;

  private handleOptionSelection(selectedValues: any[] | any): void {
    if (!selectedValues || (!Array.isArray(selectedValues) && !selectedValues.length)) return;
    const values = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    const addedValues = values.filter(val => !this.previousValue?.includes(val));
    const removedValues = this.previousValue?.filter(val => !values.includes(val));

    this.resolvedOptions?.forEach(option => {
      if (values.includes(option[this.props['valueProp']])) {
        if (option?.parent_code) {
          const parentOption = this.resolvedOptions.find(opt => opt.code === option.parent_code);
          if (parentOption && !values.includes(parentOption[this.props['valueProp']])) {
            values.push(parentOption[this.props['valueProp']]);
            this.formControl.patchValue(values, { emitEvent: false });
          }
        }
      }
      const childOption = this.resolvedOptions?.find(opt => opt.code === option.child_code);
      if (childOption?.code && removedValues?.length) {
        const updatedValues = values.filter(val => val !== childOption[this.props['valueProp']]);
        this.formControl.patchValue(updatedValues, { emitEvent: false });
      }
    });
    this.cdr.detectChanges();
  }

  onSearch(searchText: string) {
    if (!this.props.nzServerSearch) return;
    this.searchChange$.next(searchText);
  }

  private searchData(term: string): Observable<any[]> {
    this.props?.maxTagCount && this.props?.emitList && this.props.emitList([], this.field);
    return this.props.listOptionDropdown(1, { [this.props.keySearch || 'search']: term });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getSelectedLabel(value: any | any[]): string {
    if (!this.resolvedOptions || value == null) return 'Chưa có dữ liệu';
    if (Array.isArray(value)) {
      if (!value?.length) return 'Chưa có dữ liệu';
      const labels = cloneDeep(value)
        .map(val => this.getLabelForValue(val))
        .filter(label => label);
      return labels.join(', ');
    }
    return this.getLabelForValue(value);
  }

  private getLabelForValue(val: any): string {
    const selectedOption = this.resolvedOptions.find(option => option[this.props['valueProp']] === val);
    return selectedOption
      ? selectedOption[this.props['labelProp']] || selectedOption.value || selectedOption.title || selectedOption.name
      : 'Chưa có dữ liệu';
  }

  loadMore(): void {
    if (!this.props.nzServerSearch || this.pagination.total_pages === this.pagination.page) return;
    this.isLoading = true;
    this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: true }));
    this.props.listOptionDropdown(this.pagination.page + 1, { [this.props.keySearch || 'search']: this.keySearch }).subscribe((data: any) => {
      this.isLoading = false;
      this.resolvedOptions = [...this.resolvedOptions, ...data?.items];
      this.resolvedOptions = this.resolvedOptions?.map(option => ({ ...option, disabled: false }));
      this.pagination = data?.pagination;
      this.cdr.detectChanges();
    });
  }

  protected handleAddNew(): void {
    if (this.props.functionAddNew) {
      this.props.functionAddNew(this.field, () => {
        this.ngOnInit();
        this.cdr.detectChanges();
      });
    }
  }

  protected onSelectOpenChange(event: any) {
    if (event && this.props.nzServerSearch) {
      this.onSearch('');
    }
  }
}