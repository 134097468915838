import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

  /**
   * Lưu data vào localStorage với key và thời gian hết hạn là 23:59:59 của ngày hiện tại.
   * @param key - Tên key của dữ liệu
   * @param data - Dữ liệu cần lưu
   */
  public setItem(key: string, data: unknown, expireTime?: boolean): void {
    const now = new Date();

    // Tính thời gian đến 23:59:59 của ngày hiện tại
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 0, 0);
    
    // Tính thời gian expire (milliseconds từ thời điểm hiện tại đến cuối ngày)
    const expireTimeSet = expireTime ? endOfDay.getTime() : null;

    const item = {
      value: data,
      expiry: expireTimeSet // Thiết lập thời gian hết hạn là cuối ngày
    };

    localStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * Lấy dữ liệu từ localStorage. Nếu dữ liệu đã hết hạn, nó sẽ được xóa khỏi localStorage.
   * @param key - Tên key của dữ liệu
   * @returns Dữ liệu đã lưu hoặc null nếu không tồn tại hoặc đã hết hạn
   */
  public getItem(key: string) {
    const storedItem = localStorage.getItem(key);

    if (!storedItem) {
      return null;
    }

    const parsedItem = JSON.parse(storedItem);

    // Nếu không có thông tin expire hoặc expire null, trả về trực tiếp giá trị
    if (!parsedItem.expiry) {
      return parsedItem.value;
    }

    const now = new Date().getTime();

    // Kiểm tra nếu đã hết hạn
    if (now > parsedItem.expiry && parsedItem.expiry) {
      localStorage.removeItem(key); // Xóa item nếu đã hết hạn
      return null;
    }

    return parsedItem.value;
  }

  /**
   * Lấy và giải mã token từ localStorage (nếu có) và kiểm tra expire time của token.
   * @returns Thông tin token hoặc null nếu token không hợp lệ hoặc hết hạn.
   */
  public getTokenData(): any {
    const token = this.getItem('token'); // Sử dụng getItem để kiểm tra expire time của token

    if (!token) {
      return null;
    }

    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  /**
   * Xóa một item khỏi localStorage theo key
   * @param key - Tên key của dữ liệu cần xóa
   */
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Xóa toàn bộ dữ liệu trong localStorage
   */
  public clear(): void {
    localStorage.clear();
  }

  /**
   * Xóa toàn bộ dữ liệu trong localStorage (hàm này tương tự như clear)
   */
  public clearAllLocalStorage(): void {
    localStorage.clear();
  }
}